const en = {

  navbar: {

    mintACitizen: "MINT A CITIZEN",

  }

}

export default en;