import { useEffect, useRef } from "react";
import './ComingSoon.css';
// import { BrowserView, MobileView } from 'react-device-detect';
import { NavLink } from 'react-router-dom';


function ComingSoon () {

  // const [ showLogo, setShowLogo ] = useState( 'translate-y-14 opacity-0' );

  const logoRef = useRef();
  const textRef = useRef();

  useEffect( () => {

    setTimeout( () => {

      logoRef.current.style.transform = 'translateY( 0px )';
      logoRef.current.style.opacity = '1';
  
    }, 1000 )

    setTimeout( () => {

      logoRef.current.style.transform = 'translateY( 0px ) scale( 30 )';
  
    }, 3500 )

    setTimeout( () => {

      textRef.current.style.transform = 'translateY( 0px )';
      textRef.current.style.opacity = '1';
  
    }, 4500 )

  } )

  return (

    <div className="h-screen w-screen relative flex justify-center items-center cmgsoon-container overflow-clip">

      <img 
        src={ window.innerHeight <= 1080 ? "/images/bg.dreamdomain.banner.2k.jpg" : "/images/bg.dreamdomain.banner.4k.jpg" }
        alt='' 
        className="h-screen w-screen object-cover absolute brightness-90"
      />

      <img 
        ref={ logoRef }
        className={ `absolute h-1/2 transition duration-[1500ms] translate-y-14 opacity-0` }
        src="/images/logo.dreamdomain.final.svg" 
        alt="Dream Domain logo"
      />

      <div
        ref={ textRef }
        className="text-xl lg:text-4xl flex flex-col justify-center items-center absolute transition duration-2000 text-black translate-y-14 opacity-0"
      >
        <div className="my-8 text-center">To the world of Dream Domain</div>

        <NavLink className='p-2 text-center border-2 border-black' to="/home">Take me away →</NavLink>


      </div>

    </div>

  )

}

export default ComingSoon;