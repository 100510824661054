import './App.css';
import ComingSoon from './pages/ComingSoon';
import HomeParallax from './pages/HomeParallax';
// import Home from './pages/Home';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {

  return (

    <>
      <BrowserRouter>

        <ParallaxProvider>

          {/* <HomeParallax /> */}
          {/* <ComingSoon /> */}

          <Routes>

            <Route exact path='/' element={ <ComingSoon /> } />

            <Route exact path='/home' element={ <HomeParallax /> } />

          </Routes>

        </ParallaxProvider>

      </BrowserRouter>

    </>

  )
}

export default App;
