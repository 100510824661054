import './ProfileCard.css';
import { useRef } from 'react'

function ProfileCard ( props ) {

  const { name, imgSrc } = props;

  const plate = useRef();

  function setStyle ( appear ) {

    if ( appear ) {
      
      plate.current.style.zIndex = 10;
      plate.current.style.backgroundColor = '#8D7EBAdd';
      plate.current.style.color = 'white';

    }

    else {

      plate.current.style.zIndex = -10;
      plate.current.style.backgroundColor = 'white';
      plate.current.style.color = 'white';

    }
    
  }

  return (

    <>
    
    <div 
      className='profile flex justify-center items-end m-4' 
      onMouseEnter={ () => setStyle( true ) } 
      onMouseLeave={ () => setStyle( false ) }
    >

      <img className='object-contain w-full h-full' src={ imgSrc } alt=''/>

      <h1 className='absolute font-spacegrotesk font-light bg-white text-lg md:text-2xl text-white -z-10 text-center transition-all ' ref={ plate }>

        { name }

      </h1>

    </div>
    
    </>

  )

}

ProfileCard.defaultProps = {

  name: 'Name',

}

export default ProfileCard;