import './UtilityCard.css';

function UtilityCard ( props ) {

  return (

    <div className='card-container'>

      <div>

        <img src={ props.imgSrc } className="icon" alt="icon"></img>

      </div>

      <div className="card bg-white/90">

        <div className='text-purple-800 font-medium text-center'>

          { props.children }

        </div>
      
      </div>

    </div>

  )

}

export default UtilityCard;