import './ParallaxClippedBlock.css';
import { useState, useEffect } from 'react';
import { useParallax } from 'react-scroll-parallax';

const THRESHOLD = 1024;
const SPEED_HIGH = 50;
const SPEED_LOW = 30;

function ParallaxClippedBlock ( props ) {

  const { top, btm, extent, clipImage, height, paddingTop, paddingBottom, imgHeight, bgColor } = props;

  const [ clipPath, setClipPath ] = useState( '' );
  const [ parallaxSpeed, setParallaxSpeed ] = useState( SPEED_HIGH );

  const parallaxImage = useParallax( { speed: parallaxSpeed } );

  useEffect( () => {

    if ( window.innerWidth < THRESHOLD ) {
      
      setParallaxSpeed( SPEED_LOW );
      setClipPath( getClippingPath( top, btm, extent / 2 ) );

    } else { 
      
      setParallaxSpeed( SPEED_HIGH );
      setClipPath( getClippingPath( top, btm, extent ) );

    }

    window.addEventListener( 'resize', resizeHandler )

    function resizeHandler () {

      if ( window.innerWidth < THRESHOLD ) {
        
        setParallaxSpeed( SPEED_LOW );
        
        setClipPath( getClippingPath( top, btm, extent / 2 ) );
  
      } else {
        
        setParallaxSpeed( SPEED_HIGH );
  
        setClipPath( getClippingPath( top, btm, extent ) );
  
      }
  
    }

    return () => {

      window.removeEventListener( 'resize', resizeHandler )

    }

  }, [ top, btm, extent ] )

  function getClippingPath ( tp, bt, e ) {

    // Clipping vertices
    let a, b, c, d;

    switch ( tp ) {

      case -1:
        a = `0% 0%`;
        b = `100% ${ e }%`;
        break;
  
      case 1:
        a = `0% ${ e }%`;
        b = `100% 0%`;
        break;

      default:
        a = `0% 0%`;
        b = `100% 0%`;
  
    }
  
    switch ( bt ) {
  
      case -1:
        c = `100% 100%`;
        d = `0% ${ 100 - e }%`;
        break;
  
      case 1:
        c = `100% ${ 100 - e }%`;
        d = `0% 100%`;
        break;

      default:
        c = `100% 100%`;
        d = `0% 100%`;
  
    }

    return `polygon( ${ a }, ${ b }, ${ c }, ${ d }, ${ a } )`;

  }

  return (

    <div 
      className="section p-8"
      style={ !clipImage ? { clipPath, height, paddingTop, paddingBottom } : { height, paddingTop, paddingBottom } }
    >

      <div ref={ parallaxImage.ref } className='parallax-image' style={ clipImage ? { clipPath, height: imgHeight, backgroundColor: bgColor } : { height: imgHeight, backgroundColor: bgColor } }>
        <img
          src={ props.bgImage }
          alt='parallax background'
          className='object-cover h-full w-full'
        />
      </div>

      { props.children }

    </div>

  )

}

ParallaxClippedBlock.defaultProps = {

  top: 0,
  btm: 0,
  extent: 0,
  bgImage: '',
  clipImage: false,
  height: '170vh',
  imgHeight: '250vh',
  paddingTop: 0,
  paddingBottom: 0,
  bgColor: '#00000000'
}

export default ParallaxClippedBlock;