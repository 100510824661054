import lang from '../lang';

function Navbar () {

  return (

    <div 
      className="
        w-full
        md:fixed 
        absolute
        z-10 

    ">

      <div className='container mx-auto flex items-center p-8 text-white'>

      <div className="md:flex flex-1 justify-start hidden">

        <div className='p-1'>

          

        </div>

      </div>

      <div className="flex flex-1 justify-center">

        <div>

          <img 
            src="/images/logo.dreamdomain-long.svg"
            alt=""
          />

        </div>

      </div>

      <div className="md:flex flex-1 justify-end hidden">

        <div className="border-2 lg:p-2 p-1 text-center" style={{ cursor: 'pointer' }}>

          { lang.en.navbar.mintACitizen }

        </div>

      </div>

      </div>

    </div>

  )

}

export default Navbar;