import Navbar from '../components/Navbar';
// import { useParallax } from 'react-scroll-parallax';
import './HomeParallax.css';
import ParallaxClippedBlock from '../components/ParallaxClippedBlock';
// import ClippedBlock from '../components/ClippedBlock';
import UtilityCard from '../components/UtilityCard';
import Footer from '../components/Footer'
// import ResponsiveRender from '../utils/ResponsiveRender';
import ProfileCard from '../components/ProfileCard';

function HomeParallax () {

  // const introBlock1 = useParallax( { speed: 50 } );

  return (

    <>

    <Navbar />

    {/* <div className='section h-section w-full'>

      <div ref={ introBlock1.ref } className='-z-10 absolute h-parallax w-full'>
        <img
          src='/images/bg.dreamdomain.banner.2k.jpg'
          alt='dream domain world'
          className='object-cover h-full max-w-full'
        />
      </div>

    </div> */}

    <div className="w-full" style={{ height: '140vh' }}>

      <div className='-z-10 absolute w-full' style={{ height: '180vh', marginTop: '0%' }}>
        <video 
          src='/videos/dreamdomain-promo.mp4' 
          alt='' 
          autoPlay={ true } 
          loop 
          playsInline 
          muted={ true }
          className="object-cover object-center h-full w-full mx-auto"
        />
      </div>

    </div>

    <ParallaxClippedBlock 
      top={ 1 } btm = { 1 } extent = { 10 }
      bgImage = '/images/bg.avatar.jpg'
    >

      <div className='flex flex-row justify-start items-start text-white w-full'>

        <div className='lg:ml-20 w-full' style={{ maxWidth: 500 }}>

          <div className='my-4 lg:m-4 text-2xl lg:text-4xl'>
            'If you could <span className='underline text-5xl'>REIMAGINE</span> the world, what would it be like?''
          </div>

          <div className='my-4 lg:m-4 text-xl lg:text-2xl'>
            A daydream made reality, Building one dream at a time
          </div>

        </div>

        {/* <ResponsiveRender>

          <div 
            className='absolute -z-10'
            style={{ right: '10%', top: 400, width: 1200 }}
          >

            <img 
              src='/images/avatar.png'
              alt='avatar'
              // style={{ width: 600 }}
            />

          </div>

        </ResponsiveRender> */}

      </div>

    </ParallaxClippedBlock>

    <ParallaxClippedBlock 
      top={ 1 } btm = { 0 } extent = { 10 }
      bgImage = '/images/bg.dreamdomain.banner.2k.jpg'
      clipImage = { true }
    >

      <div 
        className='font-spacegrotesk flex flex-col text-white justify-center items-center button-container'
        style={{ position: 'relative' }}
      >

        <div 
          className='flex justify-center items-center bg-cover bg-black p-3 button button1 text-3xl'
          style={{ backgroundImage: 'url( /images/bg.icecap.png )' }}
        >
          Dream Domain
        </div>

        <div 
          className='flex justify-center items-center bg-black p-3 button button2 text-3xl'
        >
          Coming Soon
        </div>

        <div 
          className='flex justify-center items-center bg-black p-3 button button3 text-3xl'
        >
          Coming Soon
        </div>

      </div>

    </ParallaxClippedBlock>

    <ParallaxClippedBlock 
      top={ 1 } btm = { 0 } extent = { 10 }
      bgImage = '/images/bg.purplewhitegradient.jpg'
    >

      <div className="container flex flex-row justify-center items-center text-white w-full"
        style={{ marginTop: '-10%' }}
      >

        <div className='mr-8 lg:block hidden w-full clip-elpis-1'>

          <img src='/images/avatar2.png' alt='avatar two' />

        </div>

        <div className=''>

          <div className='text-4xl underline font-medium my-8'>
            Elpis World            
          </div>

          <div className='my-4'>
            Dream Domain of all the different continents will be the first to be launched in Elpis World, powered by TAMc (The AceMeta Company) - inline link; lie continents such as the Dream Domain, Arena Domain, Hall of Fame Domain, and much more! A land where you are able to experience joy in many ways; playing games, building connections, and forming a positive community. 
          </div>

          <div>
            Exclusively curated Brand Partners are able to form their own virtual continents and utilise the Elpis World platform as well as list their NFTs onto Dream Domain’s marketplace to sell and advertise their products and services.


          </div>

        </div>

      </div>

    </ParallaxClippedBlock>

    <ParallaxClippedBlock 
      top={ 1 } btm={ 0 } extent={ 10 } 
      bgImage = '/images/bg.purplewhitegradient.jpg'
    >

      <div className='text-white w-full'>
        <div className='text-right text-5xl underline mx-16'>
          Utilities
        </div>
      </div>

      <div className='flex flex-row flex-wrap justify-center items-center'>

        <UtilityCard imgSrc="/images/icon.present.png">PHYGITAL REWARDS</UtilityCard>
        <UtilityCard imgSrc="/images/icon.gourd.png">EARN AND PERSONALISE</UtilityCard>
        <UtilityCard imgSrc="/images/icon.brush.png">VIRTUAL BUILDS</UtilityCard>
        <UtilityCard imgSrc="/images/icon.file.png">PRIORITY INVITES</UtilityCard>

      </div>

    </ParallaxClippedBlock>

    <ParallaxClippedBlock 
      top={ -1 } btm = { 0 } extent = { 60 }
      height="38vh"
      bgImage="/images/bg.purple.jpg"
    >

      <div className='text-white w-full container text-5xl underline pt-20'>
        Roadmap
      </div>
      
    </ParallaxClippedBlock>

    <div className='flex flex-col w-full bg-blue-100' style={{ height: '140vh' }}>

      <div className='flex bg-[#8c7eb8] w-full h-1/4'>
        
        <div className='container mx-auto flex flex-row h-full'>

          <div className='flex flex-col justify-center items-center h-full w-36 ml-4'>

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

            <div className='flex justify-center items-center'>

              <div className='absolute text-white w-24 text-center text-xl'>WE ARE HERE</div>

              <img className='' src='images/image.orb-active.png' alt='' />

            </div>

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

          </div>

          <div className='flex flex-row text-white bg-[#8c7eb8] h-full justify-center w-full sm:ml-44'>

            <img className='h-full mx-8 flex-1 object-contain lg:block hidden' src='/images/avatar.shawn-nobg-half.png' alt='' />

            <div className='flex flex-col justify-center mx-8 flex-1'>

              <div className='text-3xl font-spacegrotesk font-bold my-2'>

                NFT Version 1

              </div>

              <div className='my-2'>

                First look at the citizens of Dream Domain

              </div>

            </div>

          </div>

        </div>

      </div>

      <div className='flex bg-[#BEC8D8] w-full h-1/4'>
        
        <div className='container mx-auto flex flex-row h-full'>

          <div className='flex flex-col items-center h-full w-36 ml-4'>

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

            <img className='' src='images/image.orb-inactive.png' alt='' />

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

          </div>

          <div className='flex flex-row bg-[#BEC8D8] h-full justify-center w-full sm:ml-44'>

            <div className='flex flex-col justify-center mx-8 text-violet-900 flex-1'>

              <div className='text-3xl font-spacegrotesk font-bold my-2'>

                Staking

              </div>

              <div className='my-2'>

                <ul>
                  <li>1. Airdrop tokens</li>
                  <li>2. Airdrop &lt;Dream Pod&gt; DIY mini metaverse</li>
                  <li>3. Get priority WL for future collections</li>
                  <li>4. Exclusive events</li>
                </ul>

              </div>

            </div>

            <img className='h-full mx-8 flex-1 object-contain lg:block hidden' src='/images/avatar.mark-nobg-half.png' alt='' />

          </div>

        </div>

      </div>

      <div className='flex bg-[#a1afda] w-full h-1/4'>
        
        <div className='container mx-auto flex flex-row h-full'>

          <div className='flex flex-col items-center h-full w-36 ml-4'>

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

            <img className='' src='images/image.orb-inactive.png' alt='' />

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

          </div>

          <div className='flex flex-row text-white bg-[#a1afda] h-full justify-center w-full sm:ml-44'>

            <img className='h-full mx-8 flex-1 object-contain lg:block hidden' src='/images/avatar.charlotte-nobg-half.png' alt='' />

            <div className='flex flex-col justify-center mx-8 flex-1'>

              <div className='text-3xl font-spacegrotesk font-bold my-2'>

                Real-world and digital benefits

              </div>

              <div className='my-2'>

                1. In-game and out game collaborations, i.e. discounts as wella s mystery boxes

              </div>

            </div>

          </div>

        </div>

      </div>

      <div className='flex bg-[#E1E7F1] w-full h-1/4'>
        
        <div className='container mx-auto flex flex-row h-full'>

          <div className='flex flex-col items-center h-full w-36 ml-4'>

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

            <img className='' src='images/image.orb-inactive.png' alt='' />

            <div className='flex-1 bg-slate-300' style={{ width: '2px' }}>

            </div>

          </div>

          <div className='flex flex-row text-white bg-[#e1e7f1] h-full justify-center w-full sm:ml-44'>

            <div className='flex flex-col justify-center text-violet-900 mx-8 flex-1'>

              <div className='text-3xl font-spacegrotesk font-bold my-2'>

                Marketplace

              </div>

              <div className='my-2'>

                1. Buy & Sell upcoming NFTs

              </div>

            </div>

            <img className='h-full mx-8 flex-1 object-contain lg:block hidden' src='/images/avatar.aiker-nobg-half.png' alt='' />

          </div>

        </div>

      </div>

    </div>


    <div className="mx-auto flex flex-col justify-start items-center text-white w-full" style={{ height: '140vh'}}>

      <img 
        src='/images/bg.founders.jpg' 
        alt='' 
        className="absolute -z-10 object-cover object-center h-full w-full"
        style={{ marginTop: '-20%', height: '180vh' }}
      />

      <div className='container px-8 py-4 flex flex-col justify-center items-center'>

        <div className='w-full text-white text-5xl font-medium underline py-10' style={{ paddingTop: '10%' }}>

          Founders

        </div>

        <div 
          className='flex flex-row md:flex-row-reverse flex-wrap md:flex-wrap-reverse text-white justify-start items-center'
        >

          <ProfileCard name="Wang Heng" imgSrc="/images/avatar.wang-nobg.png"/>
          <ProfileCard name="Charlotte" imgSrc="/images/avatar.charlotte-nobg.png"/>
          <ProfileCard name="Peng Hua" imgSrc="/images/avatar.aiker-nobg.png"/>
          <ProfileCard name="Mark" imgSrc="/images/avatar.mark-nobg.png"/>
          <ProfileCard name="Shawn" imgSrc="/images/avatar.shawn-nobg.png"/>
          

        </div>

      </div>

    </div>
    

    <Footer />

    </>

  )

}

export default HomeParallax;