import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer () {

  return (

    <div className="h-96">

      <img 
        className="absolute object-cover h-96 -z-10" 
        src="/images/bg.footer.jpg"
        alt=""
      />

      <div className="flex flex-col lg:flex-row justify-between items-center h-full p-16 text-center">

        <img 
          src="/images/logo.dreamdomain-long.svg"
          alt=""
        />

        <div className="flex flex-col justify-center items-center lg:items-end text-white">

          <div className="flex flex-row justify-end items-center text-2xl">

            <div className="m-4">
              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/theacemeta' alt='Facebook link'><FontAwesomeIcon icon="fa-brands fa-facebook-f" /></a>
            </div>

            <div className="m-4">
              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/theacemeta' alt='Facebook link'><FontAwesomeIcon icon="fa-brands fa-instagram" /></a>
            </div>

            <div className="m-4">
              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/theacemeta' alt='Facebook link'><FontAwesomeIcon icon="fa-brands fa-twitter" /></a>
            </div>

            <div className="ml-4">
              <a target="_blank" rel="noreferrer" href='https://www.facebook.com/theacemeta' alt='Facebook link'><FontAwesomeIcon icon="fa-brands fa-discord" /></a>
            </div>

          </div>

          <div>
            © 2022 All Rights Reserved, TAMc (The AceMeta Company)
          </div>
          
        </div>

      </div>
    
    </div>

  )

}

export default Footer;